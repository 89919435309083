$("#subsformsubmit").click(function(){
     
    var formdata = $("#subscribeform").serialize(); 
    
    $.ajax({
        type:"POST",
        url:"subscribe",
        data:formdata,
        success:function(res){
            console.log(res);
        if(res == 200){
          $("#return-sub").html('<span class="alert alert-success">Thank You for Subscribing!</span>'); 
        }
         else{
            $("#return-sub").html('<span class="alert alert-danger">Subscribing failed!</span>');
         }}
    });
 });

 (function ($) {  

    $(document).on('click','.navbar .nav-link', function(){
        $(".navbar-collapse.show").collapse('hide');
    });
    $(document).on('click','a.navbar-brand', function(){
        $(".navbar-collapse.show").collapse('hide'); 
    });

})(jQuery);